<template>
  <v-overlay
      opacity="1"
      :value="this.isLoading()"
  >
    <v-row align="center" justify="center">
      <v-col cols="12">
        <div class="text-center">
          <v-img src="@/assets/loading.gif" max-width="256" style="margin: auto;"></v-img>
        </div>
        <div class="text-center mt-2 text-h6">
          Génération de la donnée en cours...
        </div>
      </v-col>
    </v-row>
  </v-overlay>
</template>

<script>
export default {
  name: "Loader"
}
</script>

<style scoped>

</style>
