<template>
  <v-app>
    <v-main>
      <Loader/>
      <router-view/>
    </v-main>
    <v-footer padless color="#129FDB" dark>
      <v-col
          class="text-center"
          cols="12"
      >
        {{ new Date().getFullYear() }} — <a id="link360" href="https://www.360and1.com/" target="_blank">360and1.com</a>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import Loader from "./components/Loader";

export default {
  name: 'App',
  components: {Loader},
  data: () => ({
    //
  }),
};
</script>

<style lang="scss" scoped>
#link360 {
  color: white;
}
</style>
