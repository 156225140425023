import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueApexCharts from 'vue-apexcharts'
import {mapGetters, mapMutations} from "vuex";
import axios from 'axios';

Vue.config.productionTip = false

Vue.prototype.$http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

Vue.prototype.$http.defaults.headers.post['Content-Type'] = 'application/json'

Vue.mixin({
  methods: {
    ...mapMutations([
      'activateLoading',
      'deactivateLoading'
    ]),
    ...mapGetters([
      'isLoading'
    ])
  },
})

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
