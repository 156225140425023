<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col cols="12" lg="2" md="2" sm="10" offset-sm="1" offset-md="5" offset-lg="5">
        <a id="logo360" href="https://www.360and1.com/" target="_blank">
          <v-img
              src="../assets/logo.png"
              aspect-ratio="1.7"
              contain
          ></v-img>
        </a>
      </v-col>
    </v-row>
    <v-row v-if="monthToCompare">
      <v-col
          cols="6"
      >
        <v-dialog
            ref="dialogMonthToCompare"
            v-model="modalMonthToCompare"
            :return-value.sync="monthToCompare"
            persistent
            width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="monthToCompare"
                label="Mois à comparer"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="monthToCompare"
              type="month"
              scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="modalMonthToCompare = false"
            >
              Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="saveDate($refs.dialogMonthToCompare, monthToCompare)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col
          cols="6"
      >
        <v-select
            v-model="yearsToCompare"
            multiple
            :items="[2022, 2021, 2020, 2019, 2018]"
            label="Comparer avec les années"
        ></v-select>
      </v-col>
    </v-row>
    <v-row v-if="dataCharts">
      <v-col cols="12" lg="6" md="6" sm="12">
        <v-card
            color="white"
            light
        >
          <v-card-text>
            <v-card-title>
              <span class="font-weight-bold">Evolution du chiffre d'affaire</span>
            </v-card-title>
            <apexchart type="area" height="350" :options="chartOptions" :series="turnoverSeries"></apexchart>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="12">
        <v-card
            color="white"
            light
        >
          <v-card-text>
            <v-card-title>
              <span class="font-weight-bold">Evolution des réservations</span>
            </v-card-title>
            <apexchart type="area" height="350" :options="chartOptions" :series="bookingsSeries"></apexchart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6" offset-lg="3" md="6" offset-md="3" sm="12" class="text-center mt-5 mb-5">
        <h1 class="text-h3 text-color">Carte de la métropole Française</h1>
        <h3 class="text-h6 text-color">Comparaison entre {{ monthToCompareFormatted }} et {{ monthToComparePreviousFormatted }}</h3>
      </v-col>
      <v-col cols="12" lg="6" offset-lg="3" md="6" offset-md="3" sm="12">
        <svg-map v-if="dataGrowths" @mouseover="targetOn" @mouseout="targetOff" @click="selectLocation" :map="DataFrance" :locationClass="getHeat" />
      </v-col>
    </v-row>
    <v-row justify="center" v-if="dataGrowths && selectedLocation">
      <v-dialog
          v-model="dialog"
          width="600px"
      >
        <v-card>
          <v-card-title>
            <v-row>
              <v-col class="col-3 text-h5 text-left">{{ selectedLocation.department }}</v-col>
              <v-col v-if="selectedLocation.isMinTurnoverLastYear" class="col-9 text-subtitle-1 text-right">
                <v-alert
                    dense
                    border="left"
                    type="warning"
                >
                  Chiffre d'affaire {{ selectedLocation.lastYear }} le plus bas
                </v-alert>
              </v-col>
              <v-col v-else-if="selectedLocation.isMaxTurnoverLastYear" class="col-9 text-subtitle-1 text-right">
                <v-alert
                    dense
                    border="left"
                    type="success"
                >
                  Chiffre d'affaire {{ selectedLocation.lastYear }} le plus haut
                </v-alert>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-expansion-panels multiple>
              <v-expansion-panel
                  v-for="(item, i) in selectedLocation.byYears"
                  :key="i"
              >
                <v-expansion-panel-header>
                  <h6 class="text-h6 font-weight-light">
                    {{ selectedLocation.month }} - {{ i }}
                  </h6>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <h6 class="font-weight-light">
                    Nombre d'hotels
                  </h6>
                  <p>
                    {{ item.hotels }}
                  </p>
                  <h6 class="font-weight-light">
                    Chiffre d'affaire (valeur relative)
                  </h6>
                  <p>
                    {{ item.turnover }}
                  </p>
                  <h6 class="font-weight-light">
                    Nombre de réservations
                  </h6>
                  <p>
                    {{ item.bookings }}
                  </p>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h6 class="text-h6 font-weight-light">
                    Statistiques
                  </h6>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <h6 class="font-weight-light">
                    Evolution du nombre d'hôtels
                  </h6>
                  <p>
                    {{ selectedLocation.hotelsGrowth }}%
                  </p>
                  <h6 class="font-weight-light">
                    Evolution du chiffre d'affaire
                  </h6>
                  <p>
                    {{ selectedLocation.turnoverGrowth }}%
                  </p>
                  <h6 class="font-weight-light">
                    Evolution des réservations
                  </h6>
                  <p>
                    {{ selectedLocation.bookingsGrowth }}%
                  </p>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                text
                @click="dialog = false"
            >
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
require('dayjs/locale/fr')

import {SvgMap} from "vue-svg-map"
import France from "@svg-maps/france.departments"
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.locale('fr')

export default {
  name: 'Home',
  components: {
    SvgMap
  },
  data: () => ({
    loaded: false,
    dialog: false,
    DataFrance: {
      ...France,
      locations: France.locations.map(location => {
        return location
      })
    },
    monthToCompare: null,
    monthToCompareFormatted: null,
    monthToComparePreviousFormatted: null,
    watchActivated: false,
    yearsToCompare: [],
    dataCharts: {},
    dataGrowths: {},
    selectedLocation: null,
    modalMonthToCompare: false,
    modalYearToCompare: false,
    turnoverSeries: [],
    bookingsSeries: [],
    columnsSeries: [],
    lastUpdate: null,
    chartOptions: {
      chart: {
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false,
        },
        height: 350,
        type: 'area'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'datetime',
        labels: {
          format: 'dd-MM'
        }
      }
    }
  }),
  async mounted() {
    await this.setPeriod()
    this.watchActivated = true
    await this.fetch()
  },
  methods: {
    getHeat: function (location, index) {
      let style;
      if (location.turnoverGrowth) {
        if (location.turnoverGrowth <= -25) {
          style = 'location--heat4'
        } else if (location.turnoverGrowth <= 0) {
          style = 'location--heat3'
        } else if (location.turnoverGrowth <= 25) {
          style = 'location--heat2'
        } else if (location.turnoverGrowth <= 50) {
          style = 'location--heat1'
        } else {
          style = 'location--heat0'
        }
      } else {
        style = 'location--no-heat'
      }

      if (location.isMaxTurnoverLastYear) {
        style = `${style} max-turnover`
      } else if (location.isMinTurnoverLastYear) {
        style = `${style} min-turnover`
      }

      return style;
    },
    targetOn: function (event) {
      event.target.style.fill = 'rgb(0, 143, 251)'
    },
    targetOff: function (event) {
      event.target.style.fill = ''
    },
    selectLocation: function (event) {
      this.selectedLocation = this.dataGrowths[`FR-${event.target.id}`]
      this.dialog = true
    },
    setPeriod: async function () {
      if (this.$route.name === 'HomeLastMonth') {
        let lastMonth = new Date()
        lastMonth.setDate(1)
        lastMonth.setMonth(lastMonth.getMonth() - 1)
        this.monthToCompare = lastMonth.toISOString().substr(0, 7)
        this.yearsToCompare = [lastMonth.getFullYear() - 1, lastMonth.getFullYear() - 2, lastMonth.getFullYear() - 3, lastMonth.getFullYear() - 4, lastMonth.getFullYear() - 5]
      } else {
        this.monthToCompare = new Date().toISOString().substr(0, 7)
        this.yearsToCompare = [new Date().getFullYear() - 1, new Date().getFullYear() - 2, new Date().getFullYear() - 3, new Date().getFullYear() - 4, new Date().getFullYear() - 5]
      }
      this.monthToCompareFormatted = dayjs(this.monthToCompare).format('MMMM YYYY')
      this.monthToComparePreviousFormatted = dayjs(this.monthToCompare).subtract(1, 'year').format('MMMM YYYY')
    },
    fetch: async function () {
      this.activateLoading()
      try {
        await this.fetchCharts()
        await this.fetchGrowths()
      } catch (error) {
        console.log(error)
      }
      this.deactivateLoading()
    },
    fetchGrowths: async function () {
      const [year, month] = this.monthToCompare.split('-')

      const sanitizedYearsToCompare = []
      this.yearsToCompare.forEach(element => {
        if (element != year) {
          sanitizedYearsToCompare.push(element)
        }
      })

      const response = await this.$http.get(`/hotels/heat/${month}/${year}/${sanitizedYearsToCompare}`)
      this.dataGrowths = response.data

      this.DataFrance.locations.map(location => {
        let locationData = this.dataGrowths[`FR-${location.id}`]
        if (locationData) {
          location.turnoverGrowth = locationData.turnoverGrowth
          location.bookingsGrowth = locationData.bookingsGrowth
          location.hotelsGrowth = locationData.hotelsGrowth
          location.department = locationData.department
          location.isMaxTurnoverLastYear = locationData.isMaxTurnoverLastYear ?? false;
          location.isMinTurnoverLastYear = locationData.isMinTurnoverLastYear ?? false;
        }
      })
    },
    fetchCharts: async function () {
      this.turnoverSeries = []
      this.bookingsSeries = []
      const [year, month] = this.monthToCompare.split('-')

      const sanitizedYearsToCompare = []
      this.yearsToCompare.forEach(element => {
        if (element != year) {
          sanitizedYearsToCompare.push(element)
        }
      })

      const response = await this.$http.get(`/hotels/charts/${month}/${year}/${sanitizedYearsToCompare}`)
      this.dataCharts = response.data
      for (const [key, value] of Object.entries(this.dataCharts)) {
        if (key.match(new RegExp('^\\d{4}$'))) {
          this.turnoverSeries.push({
            name: key,
            data: value.turnoverSeries
          })
          this.bookingsSeries.push({
            name: key,
            data: value.bookingsSeries
          })
        }
      }
      this.chartOptions= {
        ...this.chartOptions,
        ... {
          xaxis: {
            categories: this.dataCharts.categories
          }
        }
      }
    },
    saveDate: function (dialog, value) {
      dialog.save(value)
    }
  },
  watch: {
    monthToCompare: async function () {
      if (this.watchActivated)
        await this.fetch()
    },
    yearsToCompare: async function () {
      if (this.watchActivated)
        await this.fetch()
    }
  }
}
</script>

<style lang="scss">
.svg-map {
  width: 100%;
  height: auto;
  stroke: white;
  stroke-width: 0.5;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.svg-map__location {
  fill: #c4c4c4;
  cursor: pointer;
}

.location--no-heat {
  fill: #c4c4c4;
}

.location--heat0 {
  fill: #00FF7F;
}

.location--heat1 {
  fill: #3BCA6D;
}

.location--heat2 {
  fill: #77945C;
}

.location--heat3 {
  fill: #B25F4A;
}

.location--heat4 {
  fill: #ED2938;
}

.max-turnover {
  stroke: #4caf50;
  stroke-width: 1.5;
}

.min-turnover {
  stroke: #fb8c00;
  stroke-width: 1.5;
}

.text-color {
  color: rgba(0, 0, 0, 0.87);
}
</style>
